<template>
  <div class="page-ctx">
    <VLInfo v-model="vl" :edit="true" :enable-switch="false" />
    <div class="options">
      <van-checkbox v-model="confirmed" shape="square">确认行驶证信息完整无误!</van-checkbox>
    </div>
    <div class="btns-col" style="padding-left:var(--mp);padding-right:var(--mp);">
      <van-button type="primary" :disabled="!confirmed" @click="saveVL"> 保 存 </van-button>
      <van-button icon="arrow-left" @click="goBack"> 返 回</van-button>
    </div>
  </div>
</template>

<script>
import VLApi from '@/apis/VLApi'
import VLInfo from '@/components/VLInfo'
import { mapGetters } from 'vuex'
export default {
  components: { VLInfo },
  computed: {
    ...mapGetters(['userType'])
  },
  data() {
    return {
      vl: {},
      confirmed: false
    }
  },
  created() {
    this.vl = JSON.parse(this.$route.query.vl)
  },
  methods: {
    saveVL() {
      if (this.userType === 'STAFF') {
        this.vl.channel = 'BIZ'
        VLApi.save(this.vl).then(rsp => {
          this.vl = rsp.data
          this.$toast.success('保存成功！')
        })
      } else if (this.userType === 'CUST') {
        this.vl.channel = 'SELF'
        VLApi.custSaveVL(this.vl).then(rsp => {
          this.vl = rsp.data
          this.$toast.success('保存成功！')
        })
      }
    },
    goBack() {
      if (this.userType === 'STAFF') {
        this.$router.replace('/vl/mng')
      } else if (this.userType === 'CUST') {
        this.$router.replace('/cust-vl/mng')
      }
    }
  }
}
</script>

<style scoped>
.options{
  padding: var(--mp);
}
</style>
